function card_choose() {
  const bestImgBtns = document.querySelectorAll('.best-img-btn');

  const parent = document.getElementById('god-select');

  const over_alert = document.getElementById('over_gods');

  // 20250318
  let cards = document.querySelectorAll("input.my_cards");

  let n = cards.length;

  let bottomText = document.getElementById('bottom-select-p');
  let bottomSelectItem = document.getElementById('bottom-select-item');

  // godカード７枚の時、決定ボタン表示
  let cardEnterBtn = document.getElementById('card_enter_btn');

  // godカード７枚の時、次へボタンをアクティブ
  let nextBtn = document.getElementById('choose_next_btn');
  
  const sortedCardIds = JSON.parse(sessionStorage.getItem("sortedCardIds"));
  if (sortedCardIds) {
    let container = document.getElementById("god-select");
    sortedCardIds.forEach(id => {
      const element = document.getElementById("myCardId" + id);
      if (element) container.appendChild(element);
    });
  }

  if (n >= 1) {
    bottomText.style.display = "block";
    bottomSelectItem.style.display = "";
  }

  if (n == 7) {
    cardEnterBtn.style.display = "block";
    nextBtn.classList.add('active_btn');
  }
  // 20250318

  bestImgBtns.forEach(bib => {
    bib.addEventListener('click', () => {
      // bottomSelectItemを表示
      bottomSelectItem.style.display = "";

      // bibのvalue: best.idをパースして取得
      const bestId = JSON.parse(bib.value);
      
      // hidden_fieldのvalueにフォームで送信するためのbestIdをセット
      const currentBest = document.getElementById('current-best');
      currentBest.value = bestId;

      // godカードを表示するためのinputタグ生成
      let child = document.createElement('input');
      // bibのsrcを取得
      let cardSrc = bib.getAttribute('src');

      // godカード７枚制限
      let god_count = n + 1;
      if (god_count <= 7) {
        // クリックしたbestカードを非表示
        bib.style.display = "none";
        // bestsから選んだカードからgodカードを生成(呼び出し)
        addGod(child, cardSrc, bestId, bib);
      } else {
        over_alert.style.display = "block";
      }

      textAdd();
      enterBtnAdd();
    });
  });

  //20250314
  if (parent) {
    parent.addEventListener('click', function(e) {
      // Kiểm tra nếu element được click là input image và có các class cần thiết
      if (e.target && e.target.matches('input[type="image"].card_size_1.card_size_3.my_cards')) {
        let child = e.target;
        n--; // giảm số lượng card hiển thị
        console.log("Removed card: " + n);
        // Lấy godId từ value của card (parse từ JSON)
        const godId = JSON.parse(child.value);
        const currentGod = document.getElementById('current-god');
        currentGod.value = godId;

        // Ẩn card được click
        child.style.display = "none";

        // Nếu card được tạo động thì chúng ta đã gán data-bib-id chứa id của best card
        const bib = document.querySelector('input.best-img-btn[type="image"][value="' + godId + '"]');
        if (bib) {
          bib.style.display = "block";
        }

        // Ẩn flash message và nút quyết định
        over_alert.style.display = "none";
        const cardEnterBtn = document.getElementById('card_enter_btn');
        if (cardEnterBtn) cardEnterBtn.style.display = "none";
        const nextBtn = document.getElementById('choose_next_btn');
        if (nextBtn) nextBtn.classList.remove('active_btn');

        textRemove();
      }
    });
  }
  //20250314

  //let n = 0;

  // bestsから選んだカードからgodカードを生成
  function addGod(child, cardSrc, bestId, bib) {
    n++;
    child.type = "image";
    child.src = cardSrc;
    child.value = bestId;
    child.id = "myCardId" + bestId;
    child.classList = "card_size_1 card_size_3 my_cards";
    child.draggable = "true";
    parent.appendChild(child);
    //child = parent.appendChild(child);

    //child.addEventListener('click', () => {

    //  n--;

      // child(godの画像サブミット)のvalue: god.idをパースして取得
    //  const godId = JSON.parse(child.value);

      // hidden_fieldのvalueにフォームで送信するためのgodIdをセット
    //  const currentGod = document.getElementById('current-god');
    //  currentGod.value = godId;

      // クリックしたgodカードを非表示
    //  child.style.display = "none";
      // bestカードを表示に戻す
    //  bib.style.display = "block";

      // godカードクリックでflashメッセージ非表示
    //  over_alert.style.display = "none";
      // godカードクリックで決定ボタン非表示
    //  cardEnterBtn.style.display = "none";
      // godカードクリックで次へボタンを非アクティブ
    //  nextBtn.classList.remove('active_btn');

    //  textRemove();
    //});
  };


  // godカード７枚の時、決定ボタン表示
  //const cardEnterBtn = document.getElementById('card_enter_btn');
  // godカード７枚の時、次へボタンをアクティブ
  //const nextBtn = document.getElementById('choose_next_btn');
  
  function enterBtnAdd() {
    if (n == 7) {
      cardEnterBtn.style.display = "block";
      nextBtn.classList.add('active_btn');
    }
  }

  
  // テキスト関係の表示切り替え
  //const bottomText = document.getElementById('bottom-select-p');

  function textAdd() {
    if (n >= 1) {
      bottomText.style.display = "block";
    }
  };

  function textRemove() {
    if (n == 0) {
      bottomText.style.display = "none";
    }
  };


  // 選択カード並び替えを以降のページに反映させる為の配列情報をlink_toのパラーメーターに付与
  const submitBtn = document.getElementById('submit');
  const MSBtn = document.getElementById('move_submit');
  
  if (submitBtn) {
    const valueArr = [];
  
    submitBtn.addEventListener('click', () => {
      const sortChild = parent.children;
  
      for (let i = 0; i < sortChild.length; ++i) {
        if (sortChild[i].style.display != "none") {
          valueArr.push(sortChild[i].value);
        }
      };
  
      // submitBtn.href = submitBtn.href + valueArr;
      submitBtn.href += valueArr;
    });

    
    MSBtn.addEventListener('click', () => {
      const sortChild = parent.children;
  
      for (let i = 0; i < sortChild.length; ++i) {
        if (sortChild[i].style.display != "none") {
          valueArr.push(sortChild[i].value);
        }
      };
  
      // MSBtn.href = MSBtn.href + valueArr;
      MSBtn.href += valueArr;
    });
  }

  // 20250318
  const linkRedirectCardPage = document.getElementById("customRedirectLink");
  if (linkRedirectCardPage) {
    linkRedirectCardPage.addEventListener("click", function(event) {
      event.preventDefault();
      const editSections = document.querySelectorAll(".edit_main");
      let cardIds = [];
      editSections.forEach((section) => {
          const cardIdInput = section.querySelector("input[id='card_id']");
          const whyTextarea = section.querySelector("textarea[name^='why']");
          const howTextarea = section.querySelector("textarea[name^='how']");

          if (cardIdInput && whyTextarea && howTextarea) {
              const cardId   = cardIdInput.value;
              const whyValue = whyTextarea.value;
              const howValue = howTextarea.value;

              // Lưu vào localStorage với key là card_id
              sessionStorage.setItem(`card_${cardId}`, JSON.stringify({ why: whyValue, how: howValue }));
	      cardIds.push(cardId);
          }
      });
      sessionStorage.setItem(`sortedCardIds`, JSON.stringify(cardIds));
      window.location.href = linkRedirectCardPage.href;
    });
  }

  const cardIds = document.querySelectorAll("input[type='hidden'][id='card_id']");
  cardIds.forEach((card, index) => {
    let cardId = card.value;
    let cardKey = `card_${cardId}`;
    let data = JSON.parse(sessionStorage.getItem(cardKey));

    let whyTextarea = document.querySelector(`textarea[name="why_${index}"]`);
    let howTextarea = document.querySelector(`textarea[name="how_${index}"]`);

    if (whyTextarea && whyTextarea.value.trim() === "") {
        whyTextarea.value = data.why || "";
    }
    if (howTextarea && howTextarea.value.trim() === "") {
        howTextarea.value = data.how || "";
    }
  });
  // 20250318
  
}
window.addEventListener("load", card_choose);
