function card_sort() {
  let myCards = document.querySelectorAll('.my_cards:not([style="display: none;"])');
  myCardsSort(myCards);

  const bestImgBtns = document.querySelectorAll('.best-img-btn');

  bestImgBtns.forEach(bib => {
    bib.addEventListener('click', () => {

      let myCards = document.querySelectorAll('.my_cards:not([style="display: none;"])');
      myCardsSort(myCards);

      myCards.forEach(mc => {
        mc.addEventListener('click', () => {
          let myCards = document.querySelectorAll('.my_cards:not([style="display: none;"])');
          myCardsSort(myCards);
        });
      });

    });
  });

  function myCardsSort(myCards) {

    myCards.forEach (elm => {
      elm.ondragstart = function (e) {
        e.dataTransfer.setData('text/plain', e.target.id);
      };

      elm.ondragover = function (e) {
        e.preventDefault();
        
        let rect = this.getBoundingClientRect();

        if ((e.clientX - rect.left) < (this.clientWidth / 2)) {
          //マウスカーソルの位置が要素の半分より左
          this.style.borderLeft = '2px solid blue';
          this.style.borderRight = '';
        } else {
          //マウスカーソルの位置が要素の半分より右
          this.style.borderLeft = '';
          this.style.borderRight = '2px solid blue';
        }

        this.classList.add('active');
      };

      elm.ondragleave = function () {
        this.style.borderLeft = '';
        this.style.borderRight = '';

        this.classList.add('active');
      };

      elm.ondrop = function (e) {
        e.preventDefault();

        let parent = this.parentNode
        let children = document.getElementsByClassName('my_cards');

        for (let i = 0; i < children.length; i++) {
          if (children[i].style.display == "none") {
            parent.removeChild(children[i]);
          }
        };

        let id = e.dataTransfer.getData('text/plain');
        let elm_drag = document.getElementById(id);
        let rect = this.getBoundingClientRect();
        
        if ((e.clientX - rect.left) < (this.clientWidth / 2)) {
          //マウスカーソルの位置が要素の半分より左
          parent.insertBefore(elm_drag, this);
        } else {
          //マウスカーソルの位置が要素の半分より右
          parent.insertBefore(elm_drag, this.nextSibling);
        }
        this.style.borderLeft = '';
        this.style.borderRight = '';

        myCards.forEach(elm_style => {
          elm_style.classList.remove('active');
        });
      };
    });

  };
  
}
window.addEventListener("load", card_sort);
